import { useEffect, useState } from 'react';
import {
    createLocalRuntime,
    updateLocalRuntime,
} from '../../../js/actions/reduxActions/localRuntimes';
import AdminUI from '../../../js/components/flowOrganization/AdminUI';
import LocalRuntimeCreate from '../../../js/components/flowOrganization/localRuntimes/LocalRuntimeCreate';
import LocalRuntimeDetail from '../../../js/components/flowOrganization/localRuntimes/LocalRuntimeDetail';
import LocalRuntimeDisplayToken from '../../../js/components/flowOrganization/localRuntimes/LocalRuntimeDisplayToken';
import LocalRuntimeEdit from '../../../js/components/flowOrganization/localRuntimes/LocalRuntimeEdit';
import { FLOW_ORG_PAGES } from '../../constants';
import { resolveOrgUserRole } from '../../utils/organization';
import { useAuth } from '../AuthProvider';
import PageSwitcher from '../generic/PageSwitcher';
import Loader from '../loader/Loader';
import NonAdminUI from './NonAdminUI';

const InitialOrgPage = () => {
    const [orgPageId, setOrgPageId] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const { user, tenant, fetchUser } = useAuth();

    useEffect(() => {
        // We need to wait for the user to be loaded every time we enter this screen
        // in case the user has been removed from the current tenant's organization
        const fetchCurrentUser = async () => {
            setIsLoading(true);
            await fetchUser();
            setIsLoading(false);
        };

        fetchCurrentUser();
    }, [fetchUser]);

    useEffect(() => {
        if (!tenant || !user) {
            return;
        }

        const { isUserAdmin, isTenantInOrg } = resolveOrgUserRole(user, tenant);
        const pageId =
            isUserAdmin && isTenantInOrg
                ? FLOW_ORG_PAGES.flowOrgAdminUI
                : FLOW_ORG_PAGES.flowOrgJoinedUI;

        setOrgPageId(pageId);
    }, [user, tenant]);

    if (isLoading) {
        return <Loader />;
    }

    if (!orgPageId) {
        return null;
    }

    return (
        <PageSwitcher
            pages={[
                {
                    id: FLOW_ORG_PAGES.flowOrgAdminUI,
                    label: 'Organization Settings',
                    parent: null,
                    menu: [
                        {
                            label: 'Current Organization',
                            link: '#organizationDetails',
                            visible: true,
                        },
                        {
                            label: 'Organization Invitations',
                            link: '#organizationInvitations',
                            visible: true,
                        },
                        {
                            label: 'Tenants',
                            link: '#organizationTenants',
                            visible: true,
                        },
                        {
                            label: 'Administrators',
                            link: '#organizationAdministrators',
                            visible: true,
                        },
                        {
                            label: 'Local Runtimes',
                            link: '#localRuntimes',
                            visible: true,
                        },
                    ],
                    component: AdminUI,
                },
                {
                    id: FLOW_ORG_PAGES.flowOrgJoinedUI,
                    label: 'Organization Settings',
                    parent: null,
                    menu: null,
                    component: () => <NonAdminUI tenant={tenant} />,
                },
                {
                    id: FLOW_ORG_PAGES.localRuntimeCreate,
                    label: 'Create Local Runtime',
                    parent: FLOW_ORG_PAGES.flowOrgAdminUI,
                    menu: null,
                    component: LocalRuntimeDetail(LocalRuntimeCreate, createLocalRuntime),
                },
                {
                    id: FLOW_ORG_PAGES.localRuntimeEdit,
                    label: 'Edit Local Runtime',
                    parent: FLOW_ORG_PAGES.flowOrgAdminUI,
                    menu: null,
                    component: LocalRuntimeDetail(LocalRuntimeEdit, updateLocalRuntime),
                },
                {
                    id: FLOW_ORG_PAGES.localRuntimeDisplayToken,
                    label: 'New Local Runtime Token',
                    parent: FLOW_ORG_PAGES.flowOrgAdminUI,
                    menu: null,
                    component: LocalRuntimeDisplayToken,
                },
            ]}
            wrapperSelector="flow-organisation"
            initialPageId={orgPageId}
        />
    );
};

export default InitialOrgPage;
